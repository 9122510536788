<template>
  <section id="quick-message">
    <vs-card>
      <div slot="header">
        <h3>
          {{ lang.audiences.quickMessage.title[languageSelected] }}
        </h3>
      </div>
      <div class="vx-row wizard">
        <vs-col
          vs-justify="center"
          vs-align="center"
          vs-lg="8"
          vs-xs="12"
          vs-sm="12"
        >
          <NewChatWizard
            ref="newChatWizard"
            success-redirect="/all-chats"
            @onFinish="onFinish"
          />
        </vs-col>
      </div>
    </vs-card>
  </section>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import konecta from '@/services/konecta'

export default {
  name: 'QuickMessage',
  components: {
    NewChatWizard: () =>
      import('../../all-chats/chat/outbound/NewChatWizard.vue')
  },
  computed: {
    ...mapState(['lang', 'languageSelected'])
  },
  methods: {
    ...mapMutations(['UPDATE_ACTIVE_CHAT']),
    onFinish() {
      console.warn('finish')
      this.$refs.newChatWizard.reset()
    },
    async getBots() {
      const response = await konecta.get('/bot')
      this.bots = response.data.map(element => {
        return {
          _id: element._id,
          name: element.service.name,
          service: element.service,
          company: element.company,
          platform: 'any'
        }
      })
    }
  },
  created() {
    this.UPDATE_ACTIVE_CHAT(null)
  },
  async mounted() {
    await this.getBots()
  }
}
</script>

<style lang="scss">
#quick-message {
  .wizard {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: center;
  }
}
</style>
